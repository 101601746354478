import React from 'react';
import { useState } from "react";
import '../../imagegallery.css';
import { ImageCollectionData } from './ImageCollectionData';
import DocumentMeta from 'react-document-meta';//Used for inegration of medtadata for SEO 


// Import images from a folder.
function importAll(r) {
    return r.keys().map(r);
  }
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));


function Imagegallery1() {
  const meta = {
    title: 'Kiarash Tamaddon',
    description: 'Photographer, Copyright © Kiarash Tamaddon',
    canonical: '',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'react,meta,document,html,tags'
        }
    }
  }

  return (
    
    <div className="gallerycontainer">
        

    <div class="gallerytitle"> </div>
        {ImageCollectionData.map((item, index) => {
    return (
      <DocumentMeta {...meta}>
      <div class="responsive">
        <div class="gallery">   
          <img  className="gallery-image-Home2" src={images[index]} />
          <div class="imagedescription"> {item.title}</div>
        </div> 
      </div>
      </DocumentMeta>
      
    );
  })} 
    </div>


  );
}
 
export default Imagegallery1;
