import React from 'react';
import { useState } from "react";
import '../../imagegallery.css';
// import importAll from "import-all.macro";
// const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));
import { ImageCollectionData } from './ImageCollectionData';

// Import images from a folder.
function importAll(r) {
    return r.keys().map(r);
  }
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));


function Imagegallery1() {
  const [headers, setheaders] = useState([
    { title: 'Biography', body: '', author: 'mario', id: 1 },
    { title: 'Contact', body: '', author: 'yoshi', id: 2 },
    { title: 'Instagram', body: '', author: 'mario', id: 3 }
  ])

  const ImageGalleryHome = [
    "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"
];


  return (
    // <div className="home">
    //   <img className="gallery-image-Home" src={ImageGalleryHome[1]} alt="Image1"/>
    // </div>
    // <div className="responsive">
    //      <div className="gallery">
    //         <a target="_blank" href="img_forest.jpg">
    //             <img className="gallery-image-Home" src={ImageGalleryHome[1]} alt="Image1"/>
    //         </a>
    //         <div class="desc">Add a description of the image here</div>
            
    //     </div>
    // </div>
    <div className="gallerycontainer">
        {/* <div class="responsive">
        <div class="gallery">
            <a target="_blank" href="img_5terre.jpg">
            <img className="gallery-image-Home" src={ImageGalleryHome[1]} alt="Image1"/>
            </a>
            <div class="desc">Add a description of the image here {images[1].substr(14, 47).replace(/...$/, '')} </div>
            
        </div>
        </div> */}

    <div class="gallerytitle"> Black and White</div>
        {ImageCollectionData.map((item, index) => {
    return (
      
      <div class="responsive">
        <div class="gallery">   
          <img className="gallery-image-Home" src={images[index]} />
          <div class="imagedescription"> {item.title}</div>
      </div> 
      </div>
      //     <span>{item.title}</span>
      //   </Link>
      // </li>
    );
  })} 
    </div>


  );
}
 
export default Imagegallery1;
