// Image data: abstract
import React from 'react';
// Import images from a folder.
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

export const ImageCollectionData = [
  {
    title: 'Wolfsburg',
    path: ''
  },
  {
    title: 'Wolfsburg',
    path: ''
  },
  {
    title: 'Volkswagen Arena, Wolfsburg 2020',
    path: ''
  },
  {
    title: 'Velstove',
    path: ''
  },
  {
    title: 'Wendschot',
    path: ''
  },
  {
    title: 'Mittellandkanal, Rühen-Wolfsburg',
    path: ''
  },
  {
    title: 'Wolfsburg',
    path: ''
  },
  {
    title: 'Allersee-Wolfsburg',
    path: ''
  },
  {
    title: 'Central Station-Wolfsburg, 2020',
    path: ''
  }
];
