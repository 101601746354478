
import './App.css';

import Header from "./sidenavigation/components/Header";

import React, { useState } from 'react';

import {Helmet} from "react-helmet";
import DocumentMeta from 'react-document-meta';//Used for inegration of medtadata for SEO 


function App() {

  const meta = {
    title: 'Kiarash Tamaddon , Photographer Appjs',
    description: '© Kiarash Tamaddon',
    canonical: '',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'react,meta,document,html,tags'
        }
    }
  }

  return (
    <div className="App">
            <Helmet>
              
                <meta charSet="utf-8" />
                <title>Kiarash Tamaddon</title>
                <link rel="" href="" />
                <meta name="description" content="Kiarash Tamaddon Photos" />
            </Helmet>
     
      
          <div className="header">
          <DocumentMeta {...meta}>  
            <Header  />
            </DocumentMeta>
          </div>

      <div className="footer">© 2022 KIARASH TAMADDON </div>
         
    </div>
    
    
  );
}

export default App;
