// Image data: abstract
import React from 'react';
// Import images from a folder.
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

export const ImageCollectionData = [
  {
    title: 'Soleness I',
    path: ''
  },
  {
    title: 'Soleness II ',
    path: ''
  },
  {
    title: 'Soleness III',
    path: ''
  },
  {
    title: 'Soleness IV',
    path: ''
  },
  {
    title: 'Calvörde ',
    path: ''
  },
  {
    title: 'Shiraz ',
    path: ''
  }
];
