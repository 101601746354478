// Image data: abstract
import React from 'react';
// Import images from a folder.
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

export const ImageCollectionData = [
  
  {
    title: '',
    path: '/image2.jpg'
  },
  {
    title: ' ',
    path: '/image3.jpg'
  },
  {
    title: 'Point of View I ',
    path: '/image4.jpg'
  },
  {
    title: ' Point of View II',
    path: '/image5.jpg'
  },
  {
    title: ' ',
    path: {images},
  }
];
