import React from 'react';
import { useState } from "react";
import '../../imagegallery.css';
// import importAll from "import-all.macro";
// const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));
import { ImageCollectionData } from './ImageCollectionData';




function Imagegallery1() {
 


  return (
    
    <div className="gallerycontainer">
       <div class="documenttitle"> Contact </div>
       <a class="documenttext"> You may contact me via email:  </a>
       {/* <a  class="documenttext" href= "info@kiarashtamaddon.com">info@kiarashtamaddon.com <br></br><br></br><br></br></a> */}

       <a class="documenttext" href={`mailto:info@kiarashtamaddon.com`}>
        info@kiarashtamaddon.com
        <br></br><br></br><br></br><br></br><br></br><br></br>
       </a>

    </div>


  );
}
 
export default Imagegallery1;
