// Image data: abstract
import React from 'react';
// Import images from a folder.
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

export const ImageCollectionData = [
  {
    title: 'Wolfsburg 2021',
    path: ''
  },
  {
    title: 'Wolfsburg 2021',
    path: ''
  },
  {
    title: 'Wolfsburg 2021',
    path: ''
  },
  {
    title: 'Wolfsburg 2021',
    path: ''
  },
  {
    title: 'Wolfsburg 2021',
    path: ''
  },
  {
    title: 'Wolfsburg 2021',
    path: ''
  },{
    title: 'Oebisfelde 2021',
    path: ''
  }
];
