import React from 'react';
// import { useState } from "react";
import '../../imagegallery.css';
// import importAll from "import-all.macro";
// const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));
import { ImageCollectionData } from './ImageCollectionData';
// import FastImage from 'react-native-fast-image';
import { useEffect, useState } from "react";



// Import images from a folder.
function importAll(r) {
    return r.keys().map(r);
  }
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));


function Imagegallery1() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    cacheImages();
  }, []);

  const cacheImages = async () => {
    const promises = await images.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);

    setIsLoading(false);
  };

  return (
    
    <div className="gallerycontainer">
       

    <div class="gallerytitle">Landscape</div>
        {ImageCollectionData.map((item, index) => {
    return (
      
      <div class="responsive">
        <div class="gallery">   
          <img className="gallery-image-Home" src={images[index]} />
          
          <div class="imagedescription"> {item.title}</div>
      </div> 
      </div>
      
    );
  })} 
    </div>


  );
}
 
export default Imagegallery1;
