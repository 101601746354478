// Image data: abstract
import React from 'react';
// Import images from a folder.
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

export const ImageCollectionData = [
  {
    title: 'Winter Evening, Wendschot',
    path: ''
  },
  {
    title: 'In the City, Wolfsburg',
    path: ''
  },
  {
    title: 'Snowy Day, Harz 2021',
    path: ''
  },
  {
    title: 'In the City, Wolfsburg',
    path: ''
  },
  {
    title: 'Euro Final 2021, Wolfsburg',
    path: ''
  },
  {
    title: 'Revenants, Jakigor',
    path: ''
  },
  {
    title: 'Patience, Zahedan',
    path: ''
  },
  {
    title: 'Quandary سرگردانی',
    path: ''
  }
];
