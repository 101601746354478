import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { GalleriesData } from './GalleriesData';
import './header.css';
import Logo from './images/logo/KiarashLogo-OnWebPage.png';
import { NavLink } from "react-router-dom";

 



function Headernavbar() {
    
    const [InputS, setInputS] = useState(0);
    let inputStyle = {
        border: '3px solid red'
      };
      if (setInputS > 0 ) {
        inputStyle.border = '3px solid blue'
     }

    // function     handleClick() {
    //         inputStyle.border = '3px solid blue'
    // };   
      
    const [inputStyle2, setInputStyle2] = useState(0);
    const [imagesIsActive, setImagesIsActive] = useState(false);
    const [homeIsActive, setHomeIsactive] = useState(false);
    const [aboutIsActive, setAboutIsactive] = useState(false);
    const [contactIsActive, setContactIsactive] = useState(false);



  const handleClickImages = () => {
    // 👇️ toggle
    // setIsActive(current => !current);

    // 👇️ or set to true
    setImagesIsActive(true);
    setHomeIsactive(false);
    setAboutIsactive(false);
    setContactIsactive(false);
  };

  const handleClickHome = () => {
    // 👇️ toggle
    // setIsActive(current => !current);

    // 👇️ or set to true
    setImagesIsActive(false);
    setHomeIsactive(true);
    setAboutIsactive(false);
    setContactIsactive(false);
  };

  
  const handleClickAbout = () => {
    // 👇️ toggle
    // setIsActive(current => !current);

    // 👇️ or set to true
    setImagesIsActive(false);
    setHomeIsactive(false);
    setAboutIsactive(true);
    setContactIsactive(false);
  };

  
  const handleClickContact = () => {
    // 👇️ toggle
    // setIsActive(current => !current);

    // 👇️ or set to true
    setImagesIsActive(false);
    setHomeIsactive(false);
    setAboutIsactive(false);
    setContactIsactive(true);
  };

    return (
        <div>
        {/* <div className="logocontainer"> */}
        <NavLink to='/sidenavigation/pages/galleries/home/imagegallery'  style={{
          color: homeIsActive ? '#000' : '#777',
        }} onClick={() => handleClickHome()} activeStyle={{ color: '#000' }}>
          <img className="logocontainer" src={Logo} />
        </NavLink>
          
        <div className="navbar1">
        {/* <NavLink to="/sidenavigation/pages/galleries/home/imagegallery" style={{
          color: homeIsActive ? '#000' : '#777',
        }} onClick={() => handleClickHome()} activeStyle={{ color: '#000' }} ></NavLink> */}
        {/* <a href="#news">IMAGES</a> */}
        <NavLink to="/sidenavigation/pages/galleries/about/imagegallery" style={{
          color: aboutIsActive ? '#000' : '#777',
        }}  onClick={() => handleClickAbout()} activeStyle={{ color: '#000' }}>ABOUT</NavLink>
        
        <div className="dropdown">
            
            <button className="dropbtn" activeStyle={{ color: '#000' }}   style={{
          color: imagesIsActive ? '#000' : '#777',
        }}>IMAGES </button>
            <div className="dropdown-content">
            

            {GalleriesData.map((item, index) => {
                    return (
                        <li className="dropdownlist" key={index} >
                            
                        <NavLink to={item.path} onClick={() => handleClickImages()} activeStyle={{ color: '#000' }}> 
                            {item.title}
                        </NavLink>
                        </li>
                    );
                    })}
            </div>
            
        </div> 
        
        {/* <div className="dropdown">

          	possibility of adding a second dropdown
            <button className="dropbtn">DROPDOWN 
            </button>
            <div className="dropdown-content">
            <a href="#">Link 1</a>
            <a href="#">Link 2</a>
            <a href="#">Link 3</a>
            </div>
            
        </div>  */}
        
        <NavLink  to="/sidenavigation/pages/galleries/contact/imagegallery" style={{
          color: contactIsActive ? '#000' : '#777',
        }}  onClick={() => handleClickContact()} activeStyle={{ color: '#000' }}>CONTACT</NavLink>
        
        </div>
        </div>

);
}

export default Headernavbar