import React from 'react';
// Import images from a folder.
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

export const GalleriesData = [
  {
    title: 'ABSTRACT',
    path: '/sidenavigation/pages/galleries/abstract/imagegallery',
  },
  {
    title: 'BLACK & WHITE',
    path: '/sidenavigation/pages/galleries/blackAndWhite/imagegallery'
  },
  {
    title: 'CARS & BUILDINGS',
    path: '/sidenavigation/pages/galleries/carsAndBuildings/imagegallery'
  },
  {
    title: 'LANDSCAPE',
    path: '/sidenavigation/pages/galleries/landscape/imagegallery'
  },
  {
    title: 'PEOPLE',
    path: '/sidenavigation/pages/galleries/people/imagegallery'
  },
  {
    title: 'REFLECTION',
    path: '/sidenavigation/pages/galleries/reflection/imagegallery'
  },
  {
    title: 'VOLKSWAGEN',
    path: '/sidenavigation/pages/galleries/volkswagen/imagegallery'
  }
];
