// Image data: abstract
import React from 'react';
// Import images from a folder.
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

export const ImageCollectionData = [
  {
    title: 'Reflection I',
    path: ''
  },
  {
    title: 'Reflection II',
    path: ''
  },
  {
    title: 'Reflection III',
    path: ''
  },
  {
    title: 'Reflection IV',
    path: ''
  },
  {
    title: 'Reflection V',
    path: ''
  },
  {
    title: 'Reflection VI',
    path: ''
  },
  {
    title: 'Reflection VII',
    path: ''
  }  
];
