import React, { useState } from 'react';
import { useEffect } from "react";
import './header.css';
import Logo from './images/logo/KiarashLogo-OnWebPage.png';
import Media from 'react-media';
import Sidenavigation from './Sidenavigation';
import Headernavigation from './Headernavigation';

import { SidebarData } from './SidebarData';
import { Link } from 'react-router-dom';


function Header (props) {
return (

  <Media query="(min-width: 600px)">
  {matches => {
    return matches ? 
    

<div className="headerContainer">
<div >
        <Headernavigation  />
      </div>

</div> :

    <div classNameName="sidenavigation">
      <div >
        <Sidenavigation  />
      </div>
    </div>
    
  }}
  
</Media>
);

};

export default Header;
