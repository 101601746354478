// Image data: abstract
import React from 'react';
// Import images from a folder.
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

export const ImageCollectionData = [
  {
    title: 'Chabahar',
    path: {images},
  },
  {
    title: 'Wolfsburg',
    path: '/image2.jpg'
  },
  {
    title: 'Wolfsburg',
    path: '/image3.jpg'
  },
  {
    title: 'Garmsar ',
    path: '/image4.jpg'
  },
  {
    title: 'Baluchestan ',
    path: '/image2.jpg'
  },
  {
    title: 'Mount Taftan',
    path: '/image4.jpg'
  }
];
