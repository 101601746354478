import React from 'react';
import { useState } from "react";
import '../../imagegallery.css';
// import importAll from "import-all.macro";
// const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));
import { ImageCollectionData } from './ImageCollectionData';


function Imagegallery1() {
 


  return (
    
    <href className="gallerycontainer">
       <div class="documenttitle"> Kiarash Tamaddon </div>
       <href class="documenttext"> Kiarash Tamaddon began a three-year photography internship at the age of 15. He later had the opportunity to take courses in art and design at the University of the Arts Bremen. He sees photography as a way to explore his surroundings and gain a deeper understanding of various subjects, as well as a way to share his findings with others. </href>
       <div class="documenttitle"> Nazli Zaki Alamdari </div>
       <href class="documenttext"> As a project partner, Nazli Zaki Alamdari is involved in the entire creation process, including project visioning, planning, implementation, and evaluation. </href>
       <div class="documenttext"> </div>
       <span><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br></span>

    </href>


  );
}
 
export default Imagegallery1;
 