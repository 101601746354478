import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  // {
  //   title: 'HOME',
  //   path: '/sidenavigation/pages/galleries/home/imagegallery',
  //   icon: <FaIcons.FaEnvelopeOpenText />,
  //   cName: 'nav-text'
  // },
  {
    title: 'ABSTRACT',
    path: '/sidenavigation/pages/galleries/abstract/imagegallery',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text gallery-background'
  },
  {
    title: 'BLACK & WHITE',
    path: '/sidenavigation/pages/galleries/blackAndWhite/imagegallery',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text gallery-background'
  },
  {
    title: 'CARS & Buildings',
    path: '/sidenavigation/pages/galleries/carsAndBuildings/imagegallery',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text gallery-background'
  },
  {
    title: 'LANDSCAPE',
    path: '/sidenavigation/pages/galleries/landscape/imagegallery',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text gallery-background'
  },
  {
    title: 'PEOPLE',
    path: '/sidenavigation/pages/galleries/people/imagegallery',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text gallery-background'
  },
  {
    title: 'REFLECTION',
    path: '/sidenavigation/pages/galleries/reflection/imagegallery',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text gallery-background'
  },
  {
    title: 'VOLKSWAGEN',
    path: '/sidenavigation/pages/galleries/volkswagen/imagegallery',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text gallery-background'
  },
  {
    title: 'ABOUT',
    path: '/sidenavigation/pages/galleries/about/imagegallery',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text'
  },
  {
    title: 'CONTACT',
    path: '/sidenavigation/pages/galleries/contact/imagegallery',
    icon: <FaIcons.FaEnvelopeOpenText />,
    cName: 'nav-text'
  }
];
