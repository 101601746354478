import React from 'react';
import './Sidenavigation.css';
import Headernavbar from './Headernavbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import Abstract from '../pages/galleries/abstract/imagegallery';
import BlackAndWhite from '../pages/galleries/blackAndWhite/imagegallery';
import CarsAndBuildings from '../pages/galleries/carsAndBuildings/imagegallery';
import Landscape from '../pages/galleries/landscape/imagegallery';
import People from '../pages/galleries/people/imagegallery';
import Reflection from '../pages/galleries/reflection/imagegallery';
import Volkswagen from '../pages/galleries/volkswagen/imagegallery';

import Homepage from '../pages/galleries/home/imagegallery';
import Contact from '../pages/galleries/contact/imagegallery';
import About from '../pages/galleries/about/imagegallery';




// import Products from './sidenavigation/pages/Products';

function Headernavigation() {
  return (
    <>
      <Router>
        <Headernavbar />

        <Switch>
          
          <Route path='/sidenavigation/pages/galleries/home/imagegallery' component={Homepage} />
          <Route path='/sidenavigation/pages/galleries/abstract/imagegallery' component={Abstract} />
          <Route path='/sidenavigation/pages/galleries/blackAndWhite/imagegallery' component={BlackAndWhite} />
          <Route path='/sidenavigation/pages/galleries/carsAndBuildings/imagegallery' component={CarsAndBuildings} />
          <Route path='/sidenavigation/pages/galleries/landscape/imagegallery' component={Landscape} />
          <Route path='/sidenavigation/pages/galleries/people/imagegallery' component={People} />
          <Route path='/sidenavigation/pages/galleries/reflection/imagegallery' component={Reflection} />
          <Route path='/sidenavigation/pages/galleries/volkswagen/imagegallery' component={Volkswagen} />
          <Route path='/sidenavigation/pages/galleries/contact/imagegallery' component={Contact} />
          <Route path='/sidenavigation/pages/galleries/about/imagegallery' component={About} />
          <Redirect from="/" to="/sidenavigation/pages/galleries/home/imagegallery" component={Homepage}/>

        </Switch>
      </Router>
    </>
  );
}

export default Headernavigation;
